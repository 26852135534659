<div class="main-bg">
    <div class="bg-overlay"></div>
    <div class="footer-notice">Prosíme, objednávejte si pomocí čísel pípy! Děkujeme. Aktualizováno {{time?.getHours()}}:{{time?.getMinutes()}} ({{offline ? "OFFLINE" : "ONLINE"}}).</div>
    <div class="taps">
        <div class="beers">
            <div class="tap" *ngFor="let tap of taps.slice(0,9)" [class.new]="tap.keg && isNew(getKeg(tap.keg))">
                <div class="tap-id">
                    <div>{{tap?.id}}</div>
                </div>
                <div class="beer" *ngIf="tap.state !='Skrytá'">
                    <div class="beer-info" >
                        <div class="name" *ngIf="tap.state !='Zobrazená'">
                            {{tap?.state}}
                        </div>
                        <div class="name" *ngIf="tap.state =='Zobrazená'">
                            {{getKegBeer(tap.keg)?.name}}
                        </div>
                        <div class="info" *ngIf="tap.state =='Zobrazená'">
                            <span *ngIf="getKegBeer(tap.keg)?.beerstyle">{{getKegBeer(tap.keg)?.beerstyle}}</span>
                            <span *ngIf="getKegBeer(tap.keg)?.degree && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.degree}}°</span>
                            <span *ngIf="getKegBeer(tap.keg)?.alcoholVolume && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.alcoholVolume}} %</span>
                            <span *ngIf="getKegBeer(tap.keg)?.ibu && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">IBU: {{getKegBeer(tap.keg)?.ibu}}</span>
                        </div>
                    </div>
                    <div class="beer-price" *ngIf="tap.state =='Zobrazená'">
                        <div *ngFor="let volume of getKeg(tap.keg)?.soldVolumes?.sort(compareVolume)">
                            {{volume}}l {{calculatePrice(volume, getKeg(tap.keg))}} Kč
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="beers">
            <div class="tap" *ngFor="let tap of taps.slice(9)" [class.new]="tap.keg && isNew(getKeg(tap.keg))">
                <div class="tap-id">
                    <div>{{tap?.id}}</div>
                </div>
                <div class="beer" *ngIf="tap.state !='Skrytá'">
                    <div class="beer-info" >
                        <div class="name" *ngIf="tap.state !='Zobrazená'">
                            {{tap?.state}}
                        </div>
                        <div class="name" *ngIf="tap.state =='Zobrazená'">
                            {{getKegBeer(tap.keg)?.name}}
                        </div>
                        <div class="info" *ngIf="tap.state =='Zobrazená'">
                            <span *ngIf="getKegBeer(tap.keg)?.beerstyle">{{getKegBeer(tap.keg)?.beerstyle}}</span>
                            <span *ngIf="getKegBeer(tap.keg)?.degree && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.degree}}°</span>
                            <span *ngIf="getKegBeer(tap.keg)?.alcoholVolume && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.alcoholVolume}} %</span>
                            <span *ngIf="getKegBeer(tap.keg)?.ibu && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">IBU: {{getKegBeer(tap.keg)?.ibu}}</span>
                        </div>
                    </div>
                    <div class="beer-price" *ngIf="tap.state =='Zobrazená'">
                        <div *ngFor="let volume of getKeg(tap.keg)?.soldVolumes?.sort(compareVolume)">
                            {{volume}}l {{calculatePrice(volume, getKeg(tap.keg))}} Kč
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
