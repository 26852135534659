import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataManagerComponent } from './data-manager/data-manager.component';



@NgModule({
  declarations: [DataManagerComponent],
  exports: [DataManagerComponent],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
