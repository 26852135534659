import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JSONEditor } from '@json-editor/json-editor';
import JSONEditorCs from '../../../assets/i18n/json-editor/cs.json'

declare var Jodit;
declare var window;

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.css']
})
export class DataManagerComponent implements OnInit {
  constructor(private http: HttpClient) {
    window.Jodit = Jodit;
    console.log(JSONEditor.defaults, JSONEditorCs);
    JSONEditor.defaults.languages.cs = JSONEditorCs;
    JSONEditor.defaults.language = "cs";
  }
  private element;
  private editor;
  @Input() endpointSchemes;
  @Input() endpoints;
  @Input() base;
  public endpoint = null;
  public item = null;
  public dataList = [];
  public isNew = false;

  ngOnInit() {

  }

  save() {
    let output = this.editor.getValue();
    if(this.isNew) {
      this.http.post<any>(this.base + this.endpoint, output).subscribe((data) => {
        console.log("NEW", data);
      });
    } else {
      this.http.patch<any>(this.base + this.endpoint + "/" + this.item, output).subscribe((data) => {
        console.log("UPDATE", data);
      });
    }
  }

  selectEndpoint(endpoint) {
    this.isNew = false;
    this.item = null;
    this.endpoint = endpoint.replace(/-/g, "_");
    this.getEndpointData();
  }

  selectItem(item) {
    this.item = item;
    this.isNew = false;
    this.http.get<any>(this.base + this.endpoint + "/" + item).subscribe((data) => {
      console.log(data);
      this.initEditor(data);
    })
  }

  newItem() {
    this.item = null;
    this.isNew = true;
    setTimeout(() => {
      this.initEditor();
    }, 1);
  }

  initEditor(value?) {
    this.element = document.getElementById('editor-holder');
    this.editor  = new JSONEditor(this.element, {
      theme: "spectre",
      iconlib: "spectre",
      ajax: true,
      disable_edit_json: true,
      disable_collapse: true,
      disable_properties: true,
      no_additional_properties: true,
      schema: this.endpointSchemes[this.endpoint]
    });
    if(value) {
      setTimeout(() => {
        this.editor.setValue(value);
      }, 100);
    }
  }

  getEndpointData() {
    this.http.get<any>(this.base + this.endpoint).subscribe((data) => {
      console.log(data);
      this.dataList = data;
    })
  }

  back() {
    if(this.item || this.isNew) {
      this.selectEndpoint(this.endpoint)
    } else{
      this.endpoint = null;
    }
  }

  delete(item) {
    this.http.delete<any>(this.base + this.endpoint + "/" + item).subscribe((data) => {
      this.selectEndpoint(this.endpoint);
    })
  }

}
