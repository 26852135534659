import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { Beer } from 'src/app/models/beer.model';
import { Keg } from 'src/app/models/keg.model';
import { Tap } from 'src/app/models/tap.model';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Log } from 'src/app/models/log.model';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { Beerstyle } from 'src/app/models/beerstyle.model';
import { Beerglass } from 'src/app/models/beerglass.model';
import {
  PriceLevel,
  PriceModifier,
  PricingCategory,
} from 'src/app/models/pricingcategory.model';
@Component({
  selector: 'app-maltworm-dashboard',
  templateUrl: './maltworm-dashboard.component.html',
  styleUrls: ['./maltworm-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaltwormDashboardComponent implements OnInit {
  public breweryControl = new FormControl();
  public cityControl = new FormControl();
  public beerstyleControl = new FormControl();
  public beerglassControl = new FormControl();
  public nameControl = new FormControl();
  public beers: Beer[] = [];
  public beerglasses: Beerglass[] = [];
  public beerstyle: Beerstyle[] = [];
  public priceModifiers: PriceModifier[] = [];
  public pricingCategories: PricingCategory[] = [];
  public kegs: Keg[] = [];
  public taps: Tap[] = [];
  public logs: Log[] = [];
  public selectedKeg: Keg = null;
  public selectedBeer: Beer = null;
  public selectedBeerstyle: Beerstyle = null;
  public selectedBeerglass: Beerglass = null;
  public selectedPriceModifier: PriceModifier = null;
  public selectedPricingCategory: PricingCategory = null;
  public pricePint: number = 0;
  public tapStates: string[] = [
    'Inventura',
    'Skrytá',
    'Zobrazená',
    'Přerážíme',
  ];
  public kegStates: string[] = [
    'K dispozici',
    'Na čepu',
    'Objednáno',
    'K objednání',
    'Prázdný',
    'Vratka',
  ];
  public addingOnTap: boolean = false;
  public retaping: boolean = false;
  public prompt = window.prompt;
  public saveAmount: number = 1;
  filteredBreweries: Observable<string[]>;
  filteredCities: Observable<string[]>;
  filteredBeerstyles: Observable<string[]>;
  filteredBeerglasses: Observable<string[]>;
  filteredNames: Observable<string[]>;
  private charsOrder = {};
  public removingInProgress = false;

  constructor(
    public http: HttpClient,
    public cookie: CookieService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.http
      .get<Beer[]>(environment.server + '/maltworm_beers?sort=name')
      .subscribe((data) => {
        this.beers = data.sort((a, b) => this.mySort(a.name, b.name));
        console.log(this.beers);
        this.filteredNames = this.nameControl.valueChanges.pipe(
          startWith(''),
          map((value) =>
            _.uniq(
              _.map(
                this._filterAutocomplete(this.beers, 'name', value),
                (beer) => beer.name.trim()
              )
            ).sort((s1, s2) => this.mySort(s1, s2))
          )
        );
        this.filteredBreweries = this.breweryControl.valueChanges.pipe(
          startWith(''),
          map((value) =>
            _.uniq(
              _.map(
                this._filterAutocomplete(this.beers, 'brewery', value),
                (beer) => beer.brewery.trim()
              )
            ).sort((s1, s2) => this.mySort(s1, s2))
          )
        );
        this.filteredCities = this.cityControl.valueChanges.pipe(
          startWith(''),
          map((value) =>
            _.uniq(
              _.map(
                this._filterAutocomplete(this.beers, 'city', value),
                (beer) => beer.city
              )
            ).sort((s1, s2) => this.mySort(s1, s2))
          )
        );
      });
    this.http
      .get<Beerstyle[]>(environment.server + '/maltworm_beerstyle?sort=name')
      .subscribe((data) => {
        this.beerstyle = data.sort((a, b) => this.mySort(a.name, b.name));
        console.log(this.beerstyle);
        this.filteredBeerstyles = this.beerstyleControl.valueChanges.pipe(
          startWith(''),
          map((value) =>
            _.uniq(
              _.map(
                this._filterAutocomplete(this.beerstyle, 'name', value),
                (beerstyle) => beerstyle.name
              )
            ).sort((s1, s2) => this.mySort(s1, s2))
          )
        );
      });
    this.http
      .get<Beerglass[]>(environment.server + '/maltworm_glasstype?sort=name')
      .subscribe((data) => {
        this.beerglasses = data.sort((a, b) => this.mySort(a.name, b.name));
        console.log(this.beerstyle);
        this.filteredBeerglasses = this.beerglassControl.valueChanges.pipe(
          startWith(''),
          map((value) =>
            _.uniq(
              _.map(
                this._filterAutocomplete(this.beerglasses, 'name', value),
                (beerglass) => beerglass.name
              )
            ).sort((s1, s2) => this.mySort(s1, s2))
          )
        );
      });
    this.http
      .get<PriceModifier[]>(
        environment.server + '/maltworm_pricing_modifier?sort=name'
      )
      .subscribe((data) => {
        this.priceModifiers = data.sort((a, b) => this.mySort(a.name, b.name));
        console.log(this.priceModifiers);
      });
    this.http
      .get<PricingCategory[]>(
        environment.server + '/maltworm_pricing_category?sort=name'
      )
      .subscribe((data) => {
        this.pricingCategories = data.sort((a, b) =>
          this.mySort(a.name, b.name)
        );
        console.log(this.pricingCategories);
      });
    this.http
      .get<Keg[]>(environment.server + '/maltworm_kegs')
      .subscribe((data) => {
        this.kegs = data.sort((a, b) => this.mySort(a.name, b.name));
        console.log(this.kegs);
      });
    this.http
      .get<Tap[]>(environment.server + '/maltworm_taps')
      .subscribe((data) => {
        this.taps = data.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        console.log(this.taps);
      });
    this.http
      .get<Log[]>(environment.server + '/maltworm_logs')
      .subscribe((data) => {
        this.logs = data.reverse();
        console.log(this.logs);
      });
    let charMapL = ' 0123456789aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž';
    let charMapU = ' 0123456789AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ';
    for (let i in charMapL.split('')) {
      this.charsOrder[charMapL[i]] = parseInt(i);
      this.charsOrder[charMapU[i]] = parseInt(i);
    }
  }

  private _filterAutocomplete(
    coll: any[],
    attr: string,
    value: string
  ): string[] {
    const filterValue = value.toLowerCase();
    return coll.filter((option) =>
      option[attr].toLowerCase().includes(filterValue)
    );
  }

  changeTapState(tap: Tap, event: MatSelectChange) {
    //tap['state'] = event.value;
    this.updateTap(tap);
  }

  selectNextBeer(tap: Tap) {
    if (tap['keg'] != null) {
      alert('Musíte nejdříve odrazit!');
      return;
    }
    this.addingOnTap = true;
  }

  addOnTap(tap: Tap, keg: Keg) {
    tap['keg'] = keg['id'];
    keg['done'] = null;
    keg['onTapFrom'] = new Date().toISOString();
    keg['state'] = 'Na čepu';
    tap['state'] = 'Zobrazená';
    this.updateKeg(keg);
    this.updateTap(tap);
    this.addingOnTap = false;
    this.log(
      'Na pípu ' + tap['id'] + ' naraženo ' + this.getBeer(keg.beer)?.name
    );
  }

  selectTap() {
    this.retaping = true;
  }

  retap(from: Tap, to: Tap, keg: Keg) {
    from.keg = null;
    from.state = 'Přerážíme';
    to.keg = keg['id'];
    to.state = 'Zobrazená';
    this.updateTap(from);
    this.updateTap(to);
    let oldKeg: Keg = this.getKeg(to.keg);
    if (keg.state != 'Na čepu') {
      keg.state = 'Na čepu';
      this.updateKeg(keg);
    }
    if (oldKeg.state != 'Prázdný') {
      oldKeg.state = 'K dispozici';
      this.updateKeg(oldKeg);
    }
    this.retaping = false;
    this.log(
      this.getBeer(keg.beer)?.name +
        ' přeraženo z pípy ' +
        from['id'] +
        ' na pípu ' +
        to['id']
    );
  }

  untap(tap: Tap, keg: Keg, note: string, finish: boolean) {
    keg['done'] = new Date().toISOString();
    keg['state'] = finish ? 'Prázdný' : 'K dispozici';
    if (note) {
      keg['note'] = note;
    }
    this.updateKeg(keg);
    tap['state'] = 'Skrytá';
    tap['keg'] = null;
    this.updateTap(tap);
    this.log(
      'Pípa ' +
        tap['id'] +
        ' odraženo ' +
        (finish ? '(konec)' : '') +
        ' pivo ' +
        this.getBeer(keg.beer)?.name +
        (note ? ' - ' + note : '')
    );
  }

  updateTap(tap: Tap) {
    this.http
      .patch<Tap>(environment.server + '/maltworm_taps/' + tap.id, tap)
      .subscribe((data) => {
        console.log('UPDATE', data);
        this.http
          .get<Tap[]>(environment.server + '/maltworm_taps')
          .subscribe((data) => {
            this.taps = data.sort((a, b) => parseInt(a.id) - parseInt(b.id));
            console.log(this.taps);
          });
      });
  }

  updateBeer(beer: Beer) {
    let url = environment.server + '/maltworm_beers';
    let method = 'post';
    if (beer['id']) {
      url += '/' + beer['id'];
      method = 'put';
    } else {
      beer['id'] = 'beer-' + Date.now().toString();
    }
    this.http[method]<Beer>(url, beer).subscribe((data) => {
      console.log('UPDATE', data);
      this.http
        .get<Beer[]>(environment.server + '/maltworm_beers?sort=name')
        .subscribe((data) => {
          this.beers = data;
          console.log(this.beers);
          this.selectedBeer = null;
        });
    });
  }

  updateBeerstyle(beerstyle: Beerstyle) {
    let url = environment.server + '/maltworm_beerstyle';
    let method = 'post';
    if (beerstyle['id']) {
      url += '/' + beerstyle['id'];
      method = 'put';
    } else {
      beerstyle['id'] = 'beerstyle-' + Date.now().toString();
    }
    this.http[method]<Beerstyle>(url, beerstyle).subscribe((data) => {
      console.log('UPDATE', data);
      this.http
        .get<Beer[]>(environment.server + '/maltworm_beerstyle?sort=name')
        .subscribe((data) => {
          this.beerstyle = data;
          console.log(this.beerstyle);
          this.selectedBeerstyle = null;
        });
    });
  }

  log(message: string) {
    this.http
      .post<any>(environment.server + '/maltworm_logs', {
        id: 'log-' + Date.now().toString(),
        date: new Date().toISOString(),
        message: message,
      })
      .subscribe((data) => {
        console.log('NEW', data);
        this.http
          .get<Log[]>(environment.server + '/maltworm_logs')
          .subscribe((data) => {
            this.logs = data;
            console.log(this.logs);
          });
      });
  }

  round(num: number): number {
    return Math.round(num);
  }

  updateKeg(keg: Keg, edit: boolean = false) {
    let beer = this.getBeer(keg.beer);
    keg.name = beer.name + ' (' + keg.volume + ' l)';
    if (edit) {
      keg.priceDl = this.pricePint / 5;
    }
    if (
      keg.state == 'K dispozici' &&
      keg.correctTap != null &&
      keg.order == 0
    ) {
      const lastKeg = this.availableKegs(keg.correctTap).pop();
      keg.order = lastKeg ? lastKeg.order + 1 : 1;
    }
    if (!keg['id'] && this.saveAmount > 0) {
      for (let i = 0; i < this.saveAmount; i++) {
        keg.id = 'keg-' + Date.now().toString() + '-' + i.toString();
        this.http
          .post<Keg>(environment.server + '/maltworm_kegs', keg)
          .subscribe((data) => {
            console.log('NEW', data);
            this.http
              .get<Keg[]>(environment.server + '/maltworm_kegs')
              .subscribe((data) => {
                this.kegs = data;
                console.log(this.kegs);
              });
          });
      }
      this.selectedKeg = null;
      this.saveAmount = 1;
    } else {
      this.http
        .patch<Keg>(environment.server + '/maltworm_kegs/' + keg['id'], keg)
        .subscribe((data) => {
          console.log('UPDATE', data);
          this.http
            .get<Keg[]>(environment.server + '/maltworm_kegs')
            .subscribe((data) => {
              this.kegs = data;
              console.log(this.kegs);
              this.selectedKeg = null;
              this.saveAmount = 1;
            });
        });
    }
  }

  removeKeg(keg: Keg) {
    if (keg['id']) {
      let onTap = false;
      this.taps.forEach((tap) => {
        if (tap.keg == keg['id']) {
          onTap = true;
        }
      });
      if (onTap) {
        alert(
          'Nelze smazat sud, který je naražený na čepu. Sud nejprve odražte.'
        );
        return;
      }
      this.http
        .delete<any>(environment.server + '/maltworm_kegs/' + keg['id'])
        .subscribe((data) => {
          this.http
            .get<Keg[]>(environment.server + '/maltworm_kegs')
            .subscribe((data) => {
              this.kegs = data;
              console.log(this.kegs);
              this.selectedKeg = null;
            });
        });
    }
  }

  removeAllEmptyKegs() {
    if (confirm('Opravdu chcete smazat všechny prázdné sudy?') == true) {
      let emptyKegs = this.empty();
      if (emptyKegs.length > 0) {
        this.removingInProgress = true;
        this.removeNextEmptyKeg(emptyKegs);
      }
    }
  }

  removeNextEmptyKeg(emptyKegs) {
    let kegToRemove = emptyKegs.pop();
    this.http
      .delete<any>(environment.server + '/maltworm_kegs/' + kegToRemove.id)
      .subscribe(() => {
        if (emptyKegs.length > 0) {
          this.removeNextEmptyKeg(emptyKegs);
        } else {
          this.http
            .get<Keg[]>(environment.server + '/maltworm_kegs')
            .subscribe((data) => {
              this.kegs = data;
              this.removingInProgress = false;
              console.log(this.kegs);
              this.selectedKeg = null;
            });
        }
      });
  }

  newKeg() {
    this.selectedKeg = {
      id: null,
      state: 'Objednáno',
      beer: null,
      volume: 30,
      cost: 0,
      order: 0,
      priceDl: 0,
      correctTap: null,
      note: null,
      defaultVolume: '0.5',
      soldVolumes: ['0.3', '0.5'],
      name: 'Název sudu',
      done: null,
      onTapFrom: null,
      tapOrder: -1,
    };
    this.saveAmount = 1;
    this.pricePint = 0;
  }

  newBeer() {
    this.selectedBeer = {
      id: null,
      name: '',
      beerstyle: '',
      brewery: '',
      degree: 12,
      alcoholVolume: '',
      ibu: null,
      city: '',
      pricingModifiers: [],
      pricingCategory: null,
    };
  }

  newPriceModifier() {
    this.selectedPriceModifier = {
      id: null,
      name: '',
      modifier: 0,
    };
  }

  updatePriceModifier(priceModifier: PriceModifier) {
    let url = environment.server + '/maltworm_pricing_modifier';
    let method = 'post';
    if (priceModifier['id']) {
      url += '/' + priceModifier['id'];
      method = 'put';
    } else {
      priceModifier['id'] = 'pricemodifier-' + Date.now().toString();
    }
    this.http[method]<PriceModifier>(url, priceModifier).subscribe((data) => {
      console.log('UPDATE', data);
      this.http
        .get<PriceModifier[]>(
          environment.server + '/maltworm_pricing_modifier?sort=name'
        )
        .subscribe((data) => {
          this.priceModifiers = data;
          console.log(this.beerglasses);
          this.selectedPriceModifier = null;
        });
    });
  }

  removePriceModifier(priceModifier: PriceModifier) {
    if (priceModifier['id']) {
      this.http
        .delete<any>(
          environment.server +
            '/maltworm_pricing_modifier/' +
            priceModifier['id']
        )
        .subscribe((data) => {
          this.http
            .get<PriceModifier[]>(
              environment.server + '/maltworm_pricing_modifier?sort=name'
            )
            .subscribe((data) => {
              this.priceModifiers = data;
              console.log(this.priceModifiers);
              this.selectedPriceModifier = null;
            });
        });
    }
  }

  newPricingCategory() {
    this.selectedPricingCategory = {
      id: null,
      name: '',
      priceLevels: [
        {
          degreeFrom: 0,
          degreeTo: 0,
          priceAddition: 0,
        },
      ],
    };
  }

  updatePricingCategory(pricingCategory: PricingCategory) {
    let url = environment.server + '/maltworm_pricing_category';
    let method = 'post';
    if (pricingCategory['id']) {
      url += '/' + pricingCategory['id'];
      method = 'put';
    } else {
      pricingCategory['id'] = 'pricingcategory-' + Date.now().toString();
    }
    this.http[method]<PricingCategory>(url, pricingCategory).subscribe(
      (data) => {
        console.log('UPDATE', data);
        this.http
          .get<PricingCategory[]>(
            environment.server + '/maltworm_pricing_category?sort=name'
          )
          .subscribe((data) => {
            this.pricingCategories = data;
            console.log(this.priceModifiers);
            this.selectedPricingCategory = null;
          });
      }
    );
  }

  removePricingCategory(pricingCategory: PricingCategory) {
    if (pricingCategory['id']) {
      this.http
        .delete<any>(
          environment.server +
            '/maltworm_pricing_category/' +
            pricingCategory['id']
        )
        .subscribe((data) => {
          this.http
            .get<PricingCategory[]>(
              environment.server + '/maltworm_pricing_category?sort=name'
            )
            .subscribe((data) => {
              this.pricingCategories = data;
              console.log(this.pricingCategories);
              this.selectedPricingCategory = null;
            });
        });
    }
  }

  addPriceLevel(pricingCategory: PricingCategory) {
    this.selectedPricingCategory.priceLevels.push({
      degreeFrom: 0,
      degreeTo: 0,
      priceAddition: 0,
    });
  }

  newBeerglass() {
    this.selectedBeerglass = {
      id: null,
      name: '',
    };
  }

  updateBeerglass(beerglass: Beerglass) {
    let url = environment.server + '/maltworm_glasstype';
    let method = 'post';
    if (beerglass['id']) {
      url += '/' + beerglass['id'];
      method = 'put';
    } else {
      beerglass['id'] = 'beerglass-' + Date.now().toString();
    }
    this.http[method]<Beerglass>(url, beerglass).subscribe((data) => {
      console.log('UPDATE', data);
      this.http
        .get<Beerglass[]>(environment.server + '/maltworm_glasstype?sort=name')
        .subscribe((data) => {
          this.beerglasses = data;
          console.log(this.beerglasses);
          this.selectedBeerglass = null;
        });
    });
  }

  removeBeerglass(beerglass: Beerglass) {
    if (beerglass['id']) {
      this.http
        .delete<any>(
          environment.server + '/maltworm_glasstype/' + beerglass['id']
        )
        .subscribe((data) => {
          this.http
            .get<Beerglass[]>(
              environment.server + '/maltworm_glasstype?sort=name'
            )
            .subscribe((data) => {
              this.beerglasses = data;
              console.log(this.beerglasses);
              this.selectedBeerglass = null;
            });
        });
    }
  }

  newBeerstyle() {
    this.selectedBeerstyle = {
      id: null,
      name: '',
    };
  }

  removeBeer(beer: Beer) {
    if (beer['id']) {
      this.http
        .delete<any>(environment.server + '/maltworm_beers/' + beer['id'])
        .subscribe((data) => {
          this.http
            .get<Beer[]>(environment.server + '/maltworm_beers?sort=name')
            .subscribe((data) => {
              this.beers = data;
              console.log(this.beers);
              this.selectedBeer = null;
            });
        });
    }
  }

  removeBeerstyle(beerstyle: Beerstyle) {
    if (beerstyle['id']) {
      this.http
        .delete<any>(
          environment.server + '/maltworm_beerstyle/' + beerstyle['id']
        )
        .subscribe((data) => {
          this.http
            .get<Beerstyle[]>(
              environment.server + '/maltworm_beerstyle?sort=name'
            )
            .subscribe((data) => {
              this.beerstyle = data;
              console.log(this.beerstyle);
              this.selectedBeerstyle = null;
            });
        });
    }
  }

  modifierSelectChange(event: MatCheckboxChange, modifier: PriceModifier) {
    if (!this.selectedBeer.pricingModifiers) {
      this.selectedBeer.pricingModifiers = [];
    }
    if (
      (event.checked && this.isModifierSelected(modifier.id)) ||
      (!event.checked && !this.isModifierSelected(modifier.id))
    ) {
      return;
    } else if (event.checked) {
      this.selectedBeer.pricingModifiers.push(modifier.id);
    } else if (!event.checked) {
      this.selectedBeer.pricingModifiers =
        this.selectedBeer.pricingModifiers.filter(
          (otherModifier) => otherModifier != modifier.id
        );
    }
  }

  isModifierSelected(modifier: string) {
    if (!this.selectedBeer.pricingModifiers) {
      return false;
    }
    return this.selectedBeer.pricingModifiers.indexOf(modifier) != -1;
  }

  volumeSelectChange(event: MatCheckboxChange, volume: string) {
    if (
      (event.checked && this.isVolumeSelected(volume)) ||
      (!event.checked && !this.isVolumeSelected(volume))
    ) {
      return;
    } else if (event.checked) {
      this.selectedKeg['soldVolumes'].push(volume);
    } else if (!event.checked) {
      this.selectedKeg['soldVolumes'] = this.selectedKeg['soldVolumes'].filter(
        (otherVolume) => otherVolume != volume
      );
    }
  }

  isVolumeSelected(volume: string) {
    if (!this.selectedKeg['soldVolumes']) {
      return false;
    }
    return this.selectedKeg['soldVolumes'].indexOf(volume) != -1;
  }

  getKeg(kegId): Keg {
    return this.kegs.find((keg: Keg) => keg['id'] == kegId);
  }

  getBeer(beerId): Beer {
    return this.beers.find((beer: Beer) => beer['id'] == beerId);
  }

  getPricingCategory(categoryId): PricingCategory {
    return this.pricingCategories.find(
      (pricingCategory: PricingCategory) => pricingCategory['id'] == categoryId
    );
  }

  getPricingModifiers(modifierIds: string[] = []): PriceModifier[] {
    return this.priceModifiers.filter((modifier: PriceModifier) =>
      modifierIds.includes(modifier.id)
    );
  }

  getKegBeer(kegId): Beer {
    return this.getBeer(this.getKeg(kegId)?.beer);
  }

  hasPricing(beerId): boolean {
    console.log(this.getBeer(beerId).pricingCategory);
    return !!this.getBeer(beerId).pricingCategory;
  }

  getBeerPricingCategory(beerId): PricingCategory {
    return this.getPricingCategory(this.getBeer(beerId)?.pricingCategory);
  }

  getBeerPricingModifiers(beerId): PriceModifier[] {
    return this.getPricingModifiers(this.getBeer(beerId)?.pricingModifiers);
  }

  getTotalPricingModifiers(beerId): number {
    const modifiers = this.getPricingModifiers(
      this.getBeer(beerId)?.pricingModifiers
    );
    let sum = 0;
    for (let modifier of modifiers) {
      sum += modifier.modifier;
    }
    return sum;
  }

  formatPricingModifiers(modifiers: PriceModifier[]): string {
    return modifiers
      .map((modifier) => modifier.name + ' -> ' + modifier.modifier + ' Kč')
      .join(', ');
  }

  getBeerPricingLevel(beerId): PriceLevel {
    const beer = this.getBeer(beerId);
    const pricingCategory = this.getPricingCategory(beer.pricingCategory);
    const degree = beer.degree;
    for (let priceLevel of pricingCategory.priceLevels) {
      if (!priceLevel.degreeFrom) {
        if (degree <= priceLevel.degreeTo) {
          return priceLevel;
        }
      } else if (!priceLevel.degreeTo) {
        if (degree >= priceLevel.degreeFrom) {
          return priceLevel;
        }
      } else {
        if (degree >= priceLevel.degreeFrom && degree <= priceLevel.degreeTo) {
          return priceLevel;
        }
      }
    }
    return {
      degreeFrom: null,
      degreeTo: null,
      priceAddition: 0,
    };
  }

  availableKegs(tapId: string): Keg[] {
    return this.kegs
      .filter(
        (keg: Keg) =>
          keg['state'] == 'K dispozici' &&
          (keg.correctTap == null || keg.correctTap == tapId)
      )
      .sort((a, b) => {
        let aV = a.order ? a.order : 0;
        let bV = b.order ? b.order : 0;
        return aV - bV;
      });
  }

  orderedKegs(tapId: string): Keg[] {
    return this.kegs.filter(
      (keg: Keg) =>
        keg['state'] == 'Objednáno' &&
        (keg.correctTap == null || keg.correctTap == tapId)
    );
  }

  upPriority(keg: Keg) {
    if (keg.order == undefined || keg.order == null) {
      keg.order = 0;
    }
    this.http
      .patch<Keg>(environment.server + '/maltworm_kegs/' + keg['id'], {
        order: keg.order - 1,
      })
      .subscribe((data) => {
        console.log('UPDATE', data);
        this.http
          .get<Keg[]>(environment.server + '/maltworm_kegs')
          .subscribe((data) => {
            this.kegs = data;
          });
      });
  }

  downPriority(keg: Keg) {
    if (keg.order == undefined || keg.order == null) {
      keg.order = 0;
    }
    this.http
      .patch<Keg>(environment.server + '/maltworm_kegs/' + keg['id'], {
        order: keg.order + 1,
      })
      .subscribe((data) => {
        console.log('UPDATE', data);
        this.http
          .get<Keg[]>(environment.server + '/maltworm_kegs')
          .subscribe((data) => {
            this.kegs = data;
          });
      });
  }

  toBeOrdered(): Keg[] {
    return this.kegs.filter((keg: Keg) => keg['state'] == 'K objednání');
  }

  empty(): Keg[] {
    return this.kegs.filter((keg: Keg) => keg['state'] == 'Prázdný');
  }

  returning(): Keg[] {
    return this.kegs.filter((keg: Keg) => keg['state'] == 'Vratka');
  }

  public compareVolume(a: string, b: string) {
    return parseFloat(a) - parseFloat(b);
  }

  calculatePrice(volume, keg: Keg): number {
    let price = parseFloat(volume) * 10 * keg['priceDl'];
    if (parseFloat(volume) < parseFloat(keg['defaultVolume'])) {
      price += 4;
      if (this.getBeer(keg.beer).beerstyle == 'Lemonade') {
        price += 2;
      }
    }
    return Math.ceil(price);
  }

  search(collection, attr) {
    return (input) => {
      let url =
        'https://maltworm-admin.evetech.cz/maltworm_' +
        collection +
        '?' +
        attr +
        '_like=' +
        encodeURI(input);
      return new Promise(function (resolve) {
        if (input.length < 2) {
          return resolve([]);
        }

        fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            resolve(data);
          });
      });
    };
  }

  mySort(s1, s2) {
    let idx = 0;
    while (
      idx < s1.length &&
      idx < s2.length &&
      this.charsOrder[s1[idx]] == this.charsOrder[s2[idx]]
    ) {
      idx++;
    }
    if (idx == s1.length && idx == s2.length) return 0;
    if (idx == s1.length) return 1;
    if (idx == s2.length) return -1;
    return this.charsOrder[s1[idx]] > this.charsOrder[s2[idx]]
      ? 1
      : this.charsOrder[s1[idx]] < this.charsOrder[s2[idx]]
      ? -1
      : 0;
  }
}
