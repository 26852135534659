<mat-tab-group>
    <mat-tab label="Piva">
        <h1>Piva</h1>
        <button mat-button (click)="newBeer()" color="accent">PŘIDAT NOVÉ PIVO</button>
        <mat-card class="example-card" *ngIf="selectedBeer">
            <mat-card-header>
              <mat-card-title>{{selectedBeer?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Název piva</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedBeer.name" [matAutocomplete]="nameAuto" [formControl]="nameControl">
                    <mat-autocomplete #nameAuto="matAutocomplete" >
                        <mat-option *ngFor="let name of filteredNames | async" [value]="name">
                          {{name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Pivní styl</mat-label>
                    <input matInput type="text" name="beerstyle" [(ngModel)]="selectedBeer.beerstyle" [matAutocomplete]="beerstyleAuto" [formControl]="beerstyleControl">
                    <mat-autocomplete #beerstyleAuto="matAutocomplete">
                        <mat-option *ngFor="let beerstyle of filteredBeerstyles | async" [value]="beerstyle">
                          {{beerstyle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Typ skla</mat-label>
                    <input matInput type="text" name="beerglass" [(ngModel)]="selectedBeer.glasstype" [matAutocomplete]="beerglassAuto" [formControl]="beerglassControl">
                    <mat-autocomplete #beerglassAuto="matAutocomplete">
                        <mat-option *ngFor="let beerglass of filteredBeerglasses | async" [value]="beerglass">
                          {{beerglass}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill" color="accent">
                    <mat-label>Kategorie nacenění</mat-label>
                    <mat-select [(value)]="selectedBeer.pricingCategory">
                        <mat-option *ngFor="let pricingCategory of pricingCategories" [value]="pricingCategory['id']?.toString()">
                            {{pricingCategory?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <section>
                    <label>Modifikátory nacenění</label>
                    <div *ngFor="let modifier of priceModifiers; let i = index">
                        <mat-checkbox [checked]="isModifierSelected(modifier)" labelPosition="before" (change)="modifierSelectChange($event, modifier)"></mat-checkbox> {{modifier.name}}
                    </div>
                </section>
                <br/>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Stupňovitost</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedBeer.degree"> <span matSuffix>°</span>
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Objem alkoholu</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedBeer['alcoholVolume']">
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>IBU</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedBeer.ibu">
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Pivovar</mat-label>
                    <input matInput type="text" name="brewery" [(ngModel)]="selectedBeer.brewery" 
                    [matAutocomplete]="breweryAuto" [formControl]="breweryControl">
                    <mat-autocomplete #breweryAuto="matAutocomplete">
                        <mat-option *ngFor="let brewery of filteredBreweries | async" [value]="brewery">
                          {{brewery}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Město</mat-label>
                    <input matInput name="city" type="text" [(ngModel)]="selectedBeer.city" [matAutocomplete]="cityAuto" [formControl]="cityControl">
                    <mat-autocomplete #cityAuto="matAutocomplete" >
                        <mat-option *ngFor="let city of filteredCities | async" [value]="city">
                          {{city}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updateBeer(selectedBeer)">ULOŽIT</button>
              <button mat-button (click)="removeBeer(selectedBeer)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>Piva</div>
            <mat-list-item *ngFor="let beer of this.beers">
              <div mat-line (click)="selectedBeer = beer">{{beer.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab label="Pivní styly">
        <h1>Pivní styly</h1>
        <button mat-button (click)="newBeerstyle()" color="accent">PŘIDAT NOVÝ PIVNÍ STYL</button>
        <mat-card class="example-card" *ngIf="selectedBeerstyle">
            <mat-card-header>
              <mat-card-title>{{selectedBeerstyle?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Název pivního stylu</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedBeerstyle.name">
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updateBeerstyle(selectedBeerstyle)">ULOŽIT</button>
              <button mat-button (click)="removeBeerstyle(selectedBeerstyle)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>Pivní styly</div>
            <mat-list-item *ngFor="let beerstyle of this.beerstyle">
              <div mat-line (click)="selectedBeerstyle = beerstyle">{{beerstyle.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab label="Typy sklenic">
        <h1>Typy sklenice</h1>
        <button mat-button (click)="newBeerglass()" color="accent">PŘIDAT NOVÝ TYP SKLA</button>
        <mat-card class="example-card" *ngIf="selectedBeerglass">
            <mat-card-header>
              <mat-card-title>{{selectedBeerglass?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Název typu skla</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedBeerglass.name">
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updateBeerglass(selectedBeerglass)">ULOŽIT</button>
              <button mat-button (click)="removeBeerglass(selectedBeerglass)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>Typy skla</div>
            <mat-list-item *ngFor="let beerglass of this.beerglasses">
              <div mat-line (click)="selectedBeerglass = beerglass">{{beerglass.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab label="Kategorie nacenění">
        <h1>Kategorie nacenění</h1>
        <button mat-button (click)="newPricingCategory()" color="accent">PŘIDAT NOVOU KATEGORII NACENĚNÍ</button>
        <mat-card class="example-card" *ngIf="selectedPricingCategory">
            <mat-card-header>
              <mat-card-title>{{selectedPricingCategory?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Název kategorie nacenění</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedPricingCategory.name">
                </mat-form-field>
                <div>
                    <h2>Úrovně nacenění</h2>
                </div>
                <table cellspacing="0">
                    <tr *ngFor="let priceLevel of selectedPricingCategory.priceLevels">
                        <td><mat-form-field appearance="fill"  color="accent">
                            <mat-label>Od</mat-label>
                            <input matInput type="number" [(ngModel)]="priceLevel.degreeFrom"> <span matSuffix>°</span>
                        </mat-form-field></td>
                        <td><mat-form-field appearance="fill"  color="accent">
                            <mat-label>Do</mat-label>
                            <input matInput type="number" [(ngModel)]="priceLevel.degreeTo"> <span matSuffix>°</span>
                        </mat-form-field></td>
                        <td><mat-form-field appearance="fill"  color="accent">
                            <mat-label>Modifikátor ceny</mat-label>
                            <input matInput type="number" [(ngModel)]="priceLevel.priceAddition"> <span matSuffix>Kč</span>
                        </mat-form-field></td>
                    </tr>
                </table>
                <button mat-button (click)="addPriceLevel(selectedPricingCategory)">PŘIDAT ÚROVEŇ</button>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updatePricingCategory(selectedPricingCategory)">ULOŽIT</button>
              <button mat-button (click)="removePricingCategory(selectedPricingCategory)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>Kategorie nacenění</div>
            <mat-list-item *ngFor="let pricingCategory of this.pricingCategories">
            <div mat-line (click)="selectedPricingCategory = pricingCategory">{{pricingCategory.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab label="Modifikátory ceny">
        <h1>Modifikátory ceny</h1>
        <button mat-button (click)="newPriceModifier()" color="accent">PŘIDAT NOVÝ MODIFIKÁTOR CENY</button>
        <mat-card class="example-card" *ngIf="selectedPriceModifier">
            <mat-card-header>
              <mat-card-title>{{selectedPriceModifier?.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Název modifikátoru ceny</mat-label>
                    <input matInput type="text" [(ngModel)]="selectedPriceModifier.name">
                </mat-form-field>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Modifikátor ceny</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedPriceModifier.modifier"> <span matSuffix>Kč</span>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updatePriceModifier(selectedPriceModifier)">ULOŽIT</button>
              <button mat-button (click)="removePriceModifier(selectedPriceModifier)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>Modifikátory ceny</div>
            <mat-list-item *ngFor="let priceModifier of this.priceModifiers">
            <div mat-line (click)="selectedPriceModifier = priceModifier">{{priceModifier.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab label="Připravujeme">
        <h1>Připravujeme</h1>
        <button mat-button (click)="newKeg()" color="accent">PŘIDAT NOVÝ SUD</button>
        <mat-card class="example-card" *ngIf="selectedKeg">
            <mat-card-header>
              <mat-card-title>{{getBeer(selectedKeg.beer)?.name}} ({{selectedKeg?.volume}} l)</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill" color="accent">
                    <mat-label>Pivo</mat-label>
                    <mat-select [(value)]="selectedKeg.beer">
                        <mat-option *ngFor="let beer of beers" [value]="beer['id']?.toString()">
                            {{beer?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill" color="accent">
                    <mat-label>Pípa</mat-label>
                    <mat-select [(value)]="selectedKeg.correctTap">
                        <mat-option *ngFor="let tap of taps" [value]="tap['id']?.toString()">
                            Pípa {{tap?.id}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Objem sudu</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedKeg.volume"> <span matSuffix>l</span>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill" color="accent" >
                    <mat-label>Stav sudu</mat-label>
                    <mat-select [(value)]="selectedKeg.state">
                        <mat-option *ngFor="let kegState of kegStates" [value]="kegState">
                            {{kegState}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Nákupní cena</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedKeg.cost"> <span matSuffix>Kč</span>
                </mat-form-field>
                <br/>
                <div *ngIf="selectedKeg.beer && hasPricing(selectedKeg?.beer)">
                    Nákupka za 0,5l: {{selectedKeg?.cost/selectedKeg?.volume/2}} Kč <br/>
                    Kategorie nacenění: {{getBeerPricingCategory(selectedKeg?.beer)?.name}} -> {{getBeerPricingLevel(selectedKeg?.beer)?.priceAddition}} Kč<br/>
                    Úpravy nacenění: {{formatPricingModifiers(getBeerPricingModifiers(selectedKeg?.beer))}}<br/>
                    Výpočet ceny: (nákupka + kategorie nacenění) x 1.17 + úpravy nacenění<br/>
                    <b>Navržená cena: {{round(((selectedKeg?.cost/selectedKeg?.volume/2) + getBeerPricingLevel(selectedKeg?.beer)?.priceAddition)*1.17) + getTotalPricingModifiers(selectedKeg?.beer)}} Kč</b><br/>
                </div>
                <br/>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Prodejní cena za 0,5 l</mat-label>
                    <input matInput type="number" [(ngModel)]="pricePint"> <span matSuffix>Kč</span>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Poznámka</mat-label>
                    <textarea matInput [(ngModel)]="selectedKeg.note"></textarea>
                </mat-form-field>
                <br/>
                <mat-form-field appearance="fill"  color="accent" *ngIf="!selectedKeg['id']">
                    <mat-label>Počet sudů</mat-label>
                    <input matInput type="number" [(ngModel)]="saveAmount" >
                </mat-form-field>        
                <mat-form-field appearance="fill"  color="accent">
                    <mat-label>Pořadí na pípě</mat-label>
                    <input matInput type="number" [(ngModel)]="selectedKeg.order" >
                </mat-form-field>  
                <section>
                    <label>Objemy (vlevo se vybírá výchozí, vpravo se povoluje)</label>
                    <mat-radio-group [(ngModel)]="selectedKeg['defaultVolume']">
                        <div *ngFor="let volume of [ '0.1', '0.15', '0.2', '0.25', '0.3', '0.4', '0.5', '1']; let i = index">
                            <mat-radio-button [disabled]="!isVolumeSelected(volume)" class="example-margin" [value]="volume">{{volume}}</mat-radio-button>      
                            <mat-checkbox [checked]="isVolumeSelected(volume)" labelPosition="before" (change)="volumeSelectChange($event, volume)"></mat-checkbox>
                        </div>
                    </mat-radio-group>
                </section>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button (click)="updateKeg(selectedKeg, true)">ULOŽIT</button>
              <button mat-button (click)="removeKeg(selectedKeg)">SMAZAT</button>
            </mat-card-actions>
        </mat-card>
        <mat-list>
            <div mat-subheader>K dispozici</div>
            <div *ngFor="let tap of taps">
                <mat-list-item>
                    <div mat-line>Pípa {{tap.id}}</div>
                </mat-list-item>
                <mat-list-item *ngFor="let keg of availableKegs(tap.id)">
                    <div mat-line (click)="selectedKeg = keg; pricePint = 5 * keg['priceDl']">{{keg.name}}</div> Pořadí: {{keg.order}}<button (click)="upPriority(keg)">^</button><button (click)="downPriority(keg)">v</button>
                </mat-list-item>
            </div>
            <mat-divider></mat-divider>
            <div mat-subheader>Objednáno</div>
            <div *ngFor="let tap of taps">
                <mat-list-item>
                    <div mat-line>Pípa {{tap.id}}</div>
                </mat-list-item>
                <mat-list-item *ngFor="let keg of orderedKegs(tap.id)">
                    <div mat-line (click)="selectedKeg = keg; pricePint = 5 * keg['priceDl']">{{keg.name}}</div>
                </mat-list-item>
            </div>
            <mat-divider></mat-divider>
            <div mat-subheader>K objednání</div>
            <mat-list-item *ngFor="let keg of toBeOrdered()">
                <div mat-line (click)="selectedKeg = keg; pricePint = 5 * keg['priceDl']">{{keg.name}}</div>
            </mat-list-item>
            <div mat-subheader>Vratky</div>
            <mat-list-item *ngFor="let keg of returning()">
                <div mat-line (click)="selectedKeg = keg; pricePint = 5 * keg['priceDl']">{{keg.name}}</div>
            </mat-list-item>
            <div mat-subheader>Prázdné</div>
            <button mat-button (click)="removeAllEmptyKegs()" [disabled]="removingInProgress" color="accent">SMAZAT PRÁZDNÉ SUDY <mat-spinner style="display: inline; margin-left: 15px;" [diameter]="'20'" *ngIf="removingInProgress"></mat-spinner></button>
            <mat-list-item *ngFor="let keg of empty()">
                <div mat-line (click)="selectedKeg = keg; pricePint = 5 * keg['priceDl']">{{keg.name}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    <mat-tab *ngFor="let tap of taps" label="Pípa {{tap['id']}}"> 
        <h1>
            Pípa {{tap['id']}}         
        </h1>
        <mat-form-field appearance="fill" color="accent" >
            <mat-label>Stav pípy</mat-label>
            <mat-select #tapState [(value)]="tap['state']" (selectionChange)="changeTapState(tap)">
                <mat-option *ngFor="let tapState of tapStates" [value]="tapState">
                    {{tapState}}
                </mat-option>
                <mat-option>Jiný</mat-option>
            </mat-select>
        </mat-form-field><br/>
        <mat-form-field appearance="fill" color="accent">
            <mat-label>Vlastní stav</mat-label>
            <input matInput type="text" [(ngModel)]="tap['state']">
            <button mat-button (click)="changeTapState(tap)">Uložit</button>
        </mat-form-field>
        <br/>
        <mat-card class="example-card">
            <mat-card-header *ngIf="tap['keg'] && getKeg(tap['keg']) && getKegBeer(tap['keg'])">
              <mat-card-title>{{getKegBeer(tap['keg'])?.name}} ({{getKeg(tap['keg'])?.volume}} l)</mat-card-title>
              <mat-card-subtitle>{{ getKegBeer(tap['keg'])?.beerstyle }} {{ getKegBeer(tap['keg'])?.degree }}° {{ getKegBeer(tap['keg'])['alcoholVolume'] }}% IBU: {{ getKegBeer(tap['keg'])?.ibu }}</mat-card-subtitle>
              <mat-card-subtitle [style.color]="'red'">{{ getKeg(tap['keg'])?.state }}</mat-card-subtitle>

            </mat-card-header>
            <mat-card-content *ngIf="tap['keg'] && getKeg(tap['keg']) && getKegBeer(tap['keg'])">
                <p>
                    <span *ngIf="getKeg(tap['keg'])['onTapFrom']">Na čepu od {{getKeg(tap['keg'])['onTapFrom'] | date:'d. M. y H:mm'}} do {{getKeg(tap['keg'])['done'] | date:'d. M. y H:mm' }}</span>
                </p>
                <p *ngFor="let volume of getKeg(tap['keg'])?.soldVolumes?.sort(compareVolume)">
                    {{volume}} l {{calculatePrice(volume, getKeg(tap['keg']))}} Kč
                </p>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button *ngIf="tap['keg']" (click)="untap(tap, getKeg(tap['keg']), null, true)">KONEC SUDU</button>
              <button mat-button *ngIf="tap['keg']" (click)="untap(tap, getKeg(tap['keg']), prompt('Zadejte poznámku: '), false)">ODRAZIT</button>
              <button mat-button *ngIf="tap['keg'] == null" (click)="selectNextBeer(tap)">NARAZIT</button>
              <button mat-button *ngIf="tap['keg']" (click)="selectTap()">PŘERAZIT NA JINOU PÍPU</button>
            </mat-card-actions>
          </mat-card>
          <mat-list *ngIf="retaping">
            <div mat-subheader >Vyberte na kterou pípu přerážíte</div>
            <mat-list-item *ngFor="let otherTap of taps">
                <div mat-line (click)="retap(tap, otherTap, getKeg(tap['keg']))">Pípa {{otherTap["id"]}}</div>
            </mat-list-item>
          </mat-list>
          <mat-list *ngIf="addingOnTap">
            <div mat-subheader >Vyberte pivo k narážení</div>
            <mat-list-item *ngFor="let keg of availableKegs(tap['id'])">
                <div mat-line (click)="addOnTap(tap, keg)">{{getBeer(keg['beer'])?.name}} ({{keg['volume']}} l)</div>
            </mat-list-item>
          </mat-list>
    </mat-tab>
    <mat-tab label="Logy">
        <mat-list>
            <div mat-subheader>Log</div>
            <mat-list-item *ngFor="let log of this.logs">
              <div mat-line>{{log.date | date:'d. M. y H:mm' }}: {{log.message}}</div>
            </mat-list-item>
        </mat-list>
    </mat-tab>
    
</mat-tab-group>
<button mat-button (click)="cookie.delete('pass');router.navigate([], {
    skipLocationChange: true
  });">Odhlásit</button>
