import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { MaltwormDashboardComponent } from './admin/maltworm-dashboard/maltworm-dashboard.component';
import { AdminDatabaseComponent } from './admin/database/admin-database.component';
import { TvComponent } from './pages/tv/tv.component';
import { MenuComponent } from './pages/menu/menu.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full'},
  { path: 'tv', component: TvComponent },
  { path: 'menu', component: MenuComponent},
  { path: 'admin', redirectTo: '/admin/dashboard', pathMatch: 'full'},
  { path: 'admin/dashboard', canActivate: [AuthGuard], component: MaltwormDashboardComponent,
  runGuardsAndResolvers: 'always' },
  { path: 'admin/:module', canActivate: [AuthGuard], component: AdminDatabaseComponent,
  runGuardsAndResolvers: 'always' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
