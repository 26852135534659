import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: HttpClient, private cookie: CookieService) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if(!route.params["module"]) {   
      let pass;
      if(!this.cookie.check('pass')) {
        pass = prompt("Zadejte heslo:")
      } else {
        pass = this.cookie.get('pass');
      }
      if (CryptoJS.SHA256(pass).toString() != "6869b1d101c3d3e2dd5133a5feb9991713bf29c0c857b0c1cdb79bfd76fa1ddd") {
        this.router.navigate([]);
        this.router.navigate([]);
        return of(false);
      }
      this.cookie.set('pass', pass);
      return of(true);
    }
    return this.http.get('/assets/data/'+route.params['module']+'/data-description.json').pipe(
      map((data) => {
        console.log(data);
        let pass;
        if(!this.cookie.check('pass')) {
          pass = prompt("Zadejte heslo:")
        } else {
          pass = this.cookie.get('pass');
        }
        
        if (CryptoJS.SHA256(pass).toString() != data['password']) {
          this.router.navigate([]);
          return false;
        }
        this.cookie.set('pass', pass);
        return true;
      }),
      catchError(val => {
        this.router.navigate([]);
        return of(false)
      })
    );
  }
}