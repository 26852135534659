import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-admin-database',
  templateUrl: './admin-database.component.html',
  styleUrls: ['./admin-database.component.css']
})
export class AdminDatabaseComponent implements OnInit {
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, public cookie: CookieService, public router: Router) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params["module"]) {
        this.http.get('/assets/data/'+params['module']+'/data-description.json').subscribe((data) => {
          this.endpoints = data['endpoints'];
          this.endpointSchemes = data['endpoint-schemes'];
        })
      }
    });
  }
  public endpoints = []
  public endpointSchemes = {};
  public base = environment.server + '/';

  ngOnInit() {

  }
}
