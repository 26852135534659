import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { empty, interval, of, Subscription } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { Beer } from 'src/app/models/beer.model';
import { Keg } from 'src/app/models/keg.model';
import { Tap } from 'src/app/models/tap.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(private http: HttpClient) { 
    this.watch<Beer[]>(environment.server + '/maltworm_beers', (data: Beer[]) => {
      if(data == null) {
        this.offline = true;
      }
      else {
        this.offline = false;
        this.beers = data;
        console.log(this.beers);
      }
    });
    this.watch<Keg[]>(environment.server + '/maltworm_kegs', (data: Keg[]) => {
      if(data == null) {
        this.offline = true;
      }
      else {  
        this.offline = false;  
        this.kegs = data;
        console.log(this.kegs);
      }
    });
    this.watch<Tap[]>(environment.server + '/maltworm_taps', (data: Tap[]) => {
      if(data == null) {
        this.offline = true;
      }
      else {
        this.offline = false;
        this.taps = data.sort((a, b) => parseInt(a.id)-parseInt(b.id));
        console.log(this.taps); 
        this.time = new Date();
      }
    });
  }

  public beers: Beer[] = [];
  public kegs: Keg[] = [];
  public taps: Tap[] = [];
  public time: Date;
  public offline: boolean = false;

  ngOnInit() {

  }
  public compareVolume(a: string, b: string) {
    return parseFloat(a) - parseFloat(b);
  }

  watch<T>(path: string, success: (data: T) => void): Subscription {
    return interval(10000).pipe(
      concatMap(() => this.http.get<T>(path).pipe(catchError(() => {return of(null)}))),
    ).subscribe(success);
  }

  getKeg(kegId): Keg {
    return this.kegs.find((keg: Keg) => keg['id'] == kegId);
  }

  getBeer(beerId): Beer {
    return this.beers.find((beer: Beer) => beer['id'] == beerId);
  }

  getKegBeer(kegId) {
    return this.getBeer(this.getKeg(kegId)?.beer);
  }

  calculatePrice(volume, keg: Keg): number {
    if(!keg) {
      return;
    }
    let price = parseFloat(volume) * 10 * keg['priceDl'];
    if(parseFloat(volume) < parseFloat(keg['defaultVolume'])) {
      price += 4;
      if(this.getBeer(keg.beer) && this.getBeer(keg.beer).beerstyle == "Lemonade") {
        price += 2;
      }
    }
    return Math.ceil(price);
  }

  isNew(keg: Keg): boolean {
    if(!keg) {
      return false;
    }
    let opened = new Date(keg['onTapFrom']);
    if(opened.getDate() == new Date().getDate() && opened.getHours() < 16) {
      opened  = new Date();
      opened.setHours(16, 0);
    }
    const INTERVAL_NEW = 2 * 60 * 60 * 1000;  // 2 hours
    let now = new Date();
    return (now.valueOf() - opened.valueOf()) < INTERVAL_NEW;
  }

  availableKegs(tapId: string, kegOnTap: Keg): Keg[] {
    return this.kegs.filter((keg: Keg) => keg['state'] == "K dispozici" && (keg.correctTap == null || keg.correctTap == tapId)).sort((a, b) => { 
      let aV = a.order ? a.order : 0;
      let bV = b.order ? b.order : 0;
      return aV - bV; 
    }).filter((keg, index, self) => {
      console.log(keg.beer, kegOnTap?.beer)
      return keg.beer != kegOnTap?.beer && self.findIndex(keg2 => keg.beer == keg2.beer ) === index;
    });
  }

}
