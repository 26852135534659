<h3 style="text-align: center;">Prosíme objednávejte si pomocí čísel piv, děkujeme</h3>
<div *ngFor="let tap of taps; let i = index">
    <div *ngIf="getKeg(tap.keg)?.beer">
        {{tap?.id}}.&nbsp;<b>{{getKegBeer(tap.keg)?.name}}</b>&nbsp;<span *ngIf="getKegBeer(tap.keg)?.degree && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.degree}}°</span>,&nbsp;<span *ngIf="getKegBeer(tap.keg)?.beerstyle">{{getKegBeer(tap.keg)?.beerstyle}}</span>&nbsp;<span *ngIf="getKegBeer(tap.keg)?.alcoholVolume && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">{{getKegBeer(tap.keg)?.alcoholVolume}} %</span>&nbsp;<span *ngIf="getKegBeer(tap.keg)?.ibu && getKegBeer(tap.keg)?.beerstyle != 'Lemonade'">IBU: {{getKegBeer(tap.keg)?.ibu}}</span>
        <span style="float: right;">
            <ng-container *ngFor="let volume of getKeg(tap.keg)?.soldVolumes?.sort(compareVolume)">
                {{calculatePrice(volume, getKeg(tap.keg))}} Kč / {{volume}}l
            </ng-container>
        </span>
    </div>
    <ng-container *ngIf="getKeg(tap.keg)?.beer">
        <div *ngFor="let keg of availableKegs(tap.id, getKeg(tap.keg)).slice(0,2)">
            &nbsp;&nbsp;&nbsp;<b>{{getKegBeer(keg.id)?.name}}</b>&nbsp;<span *ngIf="getKegBeer(keg.id)?.degree && getKegBeer(keg.id)?.beerstyle != 'Lemonade'">{{getKegBeer(keg.id)?.degree}}°</span>,&nbsp;<span *ngIf="getKegBeer(keg.id)?.beerstyle">{{getKegBeer(keg.id)?.beerstyle}}</span>&nbsp;<span *ngIf="getKegBeer(keg.id)?.alcoholVolume && getKegBeer(keg.id)?.beerstyle != 'Lemonade'">{{getKegBeer(keg.id)?.alcoholVolume}} %</span>&nbsp;<span *ngIf="getKegBeer(keg.id)?.ibu && getKegBeer(keg.id)?.beerstyle != 'Lemonade'">IBU: {{getKegBeer(keg.id)?.ibu}}</span>
            <span style="float: right;">
                <ng-container *ngFor="let volume of keg?.soldVolumes?.sort(compareVolume)">
                    {{calculatePrice(volume, keg)}} Kč / {{volume}}l
                </ng-container>
            </span>
        </div>
    </ng-container>
    <span class="divider"></span>
</div>
<p style="text-align: center;">Aktuálně na čepu jsou piva, která splňují tyto požadavky: zvýrazněno, neškrtnuto. </p>
