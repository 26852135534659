<div *ngIf="endpoint; else endpointsList">
    <div *ngIf="isNew || item; else itemsList">
        <div id="editor-holder"></div>
        <button (click)="save()">ULOŽIT</button>
    </div>
</div>

<ng-template #endpointsList>
    <div class="collection endpoints" (click)="selectEndpoint(endpoint.value)" *ngFor="let endpoint of endpoints">
        <a role="button" tabindex="0">{{endpoint.title}}</a>
    </div>
</ng-template>

<ng-template #itemsList>
    <div class="collection items" (click)="selectItem(data.id)" *ngFor="let data of dataList">
        <a role="button" tabindex="0">{{data.name ? data.name : data.title ? data.title : data.id}}</a>
        <button (click)="delete(data.id)">Smazat</button>
    </div>
    <div class="collection items" (click)="newItem()">
        <button>Přidat</button>
    </div>
</ng-template>
<button *ngIf="endpoint" (click)="back()">ZPĚT</button>